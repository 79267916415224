import React from "react"
// import AnnBarImgDesk from "../images/ann-bar-desk.png"
// import AnnBarImgMob1 from "../images/ann-bar-mob-1.png"
// import AnnBarImgMob2 from "../images/ann-bar-mob-2.png"

import "../scss/announcement-bar.scss"

class AnnouncementBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isClose: false,
    }
  }

  componentDidMount() {
    const lastDateClose = localStorage.getItem("ndLastDateCloseBar")
    const today = new Date()
    const dateMonth = `${today.getDate()}${today.getMonth()}`
    const isCloseBar = lastDateClose === dateMonth ? true : false

    this.setState({
      isClose: isCloseBar,
    })
    if (!isCloseBar) {
      var element = document.getElementById("nd-ld-page")
      if (typeof element != "undefined" && element != null) {
        // Do nothing
      } else {
        document.body.classList.add("show-announcement-bar")
      }
    }
  }

  closeAnnouncementBar = () => {
    this.setState({
      isClose: true,
    })
    const today = new Date()
    localStorage.setItem(
      "ndLastDateCloseBar",
      `${today.getDate()}${today.getMonth()}`
    )
    document.body.classList.remove("show-announcement-bar")
  }

  render() {
    if (this.state.isClose) return <></>

    return (
      <div className="nd-announcement-bar">
        <div className="desktop">
          <svg
            width="692"
            height="70"
            viewBox="0 0 692 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_88_3667)">
              <path
                d="M21.2953 45.8558H16.3407V58.8561H9.18172V45.8558H3.74316V38.8116H21.2971V45.8558H21.2953Z"
                fill="#B79D62"
              />
              <path
                d="M38.4131 13.062C38.4131 7.10593 42.7936 2.7254 48.808 2.7254C54.8224 2.7254 59.1446 7.10593 59.1446 13.062C59.1446 19.0181 54.7641 23.4569 48.8363 23.4569C42.9084 23.4569 38.4131 19.0764 38.4131 13.062Z"
                fill="#B79D62"
              />
              <path
                d="M52.616 50.1233V58.8579H45.457V51.1266L39.1865 38.8134H58.3725L52.616 50.1233Z"
                fill="#B79D62"
              />
              <path
                d="M30.7773 2.65625V59.6985"
                stroke="white"
                strokeWidth="1.48689"
                strokeMiterlimit="10"
              />
              <path
                d="M1.11426 31.1774H60.4439"
                stroke="white"
                strokeWidth="1.48689"
                strokeMiterlimit="10"
              />
              <path
                d="M8.3922 15.3526H13.9085L11.206 8.34731L8.3922 15.3526ZM19.7374 19.2315C20.0306 19.9857 20.179 20.2224 20.5287 20.4626C20.8591 20.7205 21.1169 20.906 21.5409 20.9961L22.311 21.1427V22.1H14.2582V21.1427L14.6627 21.0703C14.993 20.9961 15.3428 20.8495 15.5441 20.4803C15.7084 20.1677 15.5265 19.5989 15.3799 19.1926L14.4066 16.5272H8.00715L6.81134 19.4505C6.62764 19.8939 6.6453 20.3708 6.86786 20.6658C6.97915 20.8124 7.23526 21.0314 7.63975 21.0879L8.099 21.1427V22.1H2.72754V21.1427L3.55596 20.9237C3.84916 20.8495 4.36494 20.5916 4.65816 20.316C5.04498 19.9292 5.50247 19.2315 5.87163 18.3307L11.7377 4.07983H13.5588L19.7374 19.2333V19.2315Z"
                fill="#00AEE8"
              />
            </g>
            <g clipPath="url(#clip1_88_3667)" filter="url(#filter0_d_88_3667)">
              <path
                d="M225.979 18.7021H218.711L216.96 24.0168H213.076L220.374 3.29468H224.317L231.614 24.0168H227.731L225.979 18.7021ZM222.334 6.88005C222.334 6.88005 221.864 8.92244 221.462 10.0927L219.621 15.6385H225.047L223.206 10.0927C222.826 8.92244 222.386 6.88005 222.386 6.88005H222.327H222.334Z"
                fill="white"
              />
              <path
                d="M239.322 26.9911C241.595 26.9911 243.526 26.0296 243.526 23.3685V22.4963C243.526 22.146 243.586 21.7062 243.586 21.7062H243.526C242.654 23.0181 241.364 23.7188 239.382 23.7188C235.118 23.7188 232.695 20.2751 232.695 16.1903C232.695 12.1055 234.999 8.77356 239.292 8.77356C242.535 8.77356 243.697 10.6669 243.697 10.6669H243.787C243.787 10.6669 243.757 10.4954 243.757 10.2569V9.11644H247.231V23.0927C247.231 28.1987 243.265 30.1293 239.382 30.1293C237.511 30.1293 235.528 29.6 234.097 28.8472L235.207 26.0146C235.207 26.0146 237.078 26.9762 239.322 26.9762V26.9911ZM243.615 16.2201C243.615 12.836 241.953 11.9266 239.941 11.9266C237.697 11.9266 236.437 13.559 236.437 16.071C236.437 18.583 237.779 20.5956 240.172 20.5956C241.953 20.5956 243.615 19.5744 243.615 16.2201Z"
                fill="white"
              />
              <path
                d="M257.823 8.78094C262.117 8.78094 264.42 11.9042 264.42 15.8473C264.42 16.2871 264.33 17.2785 264.33 17.2785H254.059C254.29 19.8799 256.302 21.2217 258.524 21.2217C260.916 21.2217 262.817 19.5892 262.817 19.5892L264.248 22.2429C264.248 22.2429 261.975 24.3747 258.263 24.3747C253.328 24.3747 250.265 20.8117 250.265 16.5853C250.265 12.0011 253.388 8.79587 257.823 8.79587V8.78094ZM260.685 14.7367C260.626 12.7241 259.373 11.6433 257.741 11.6433C255.848 11.6433 254.528 12.8136 254.178 14.7367H260.685Z"
                fill="white"
              />
              <path
                d="M267.482 9.13128H271.045V10.8532C271.045 11.4048 270.986 11.8743 270.986 11.8743H271.045C271.686 10.6221 273.319 8.78094 276.33 8.78094C279.625 8.78094 281.496 10.5028 281.496 14.446V24.0169H277.821V15.2063C277.821 13.395 277.352 12.1725 275.458 12.1725C273.565 12.1725 272.014 13.4248 271.463 15.2063C271.262 15.8175 271.202 16.4884 271.202 17.1891V24.0169H267.497V9.13128H267.482Z"
                fill="white"
              />
              <path
                d="M292.386 8.78094C296.27 8.78094 298.104 11.0022 298.104 11.0022L296.471 13.5441C296.471 13.5441 294.921 11.9415 292.588 11.9415C289.845 11.9415 288.063 14.0136 288.063 16.5555C288.063 19.0973 289.874 21.2217 292.707 21.2217C295.219 21.2217 297.082 19.3283 297.082 19.3283L298.514 21.9522C298.514 21.9522 296.382 24.3747 292.386 24.3747C287.452 24.3747 284.299 20.9011 284.299 16.5853C284.299 12.2694 287.452 8.79587 292.386 8.79587V8.78094Z"
                fill="white"
              />
              <path
                d="M300.497 26.4096C300.497 26.4096 301.317 27.0208 302.159 27.0208C303.18 27.0208 304.171 26.4096 304.731 25.0977L305.282 23.7858L299.006 9.13123H303.18L306.303 17.7108C306.594 18.5009 306.855 19.7233 306.855 19.7233H306.915C306.915 19.7233 307.146 18.5531 307.414 17.7704L310.336 9.13123H314.391L307.735 26.2605C306.683 28.9738 304.611 30.144 302.368 30.144C300.586 30.144 299.274 29.1825 299.274 29.1825L300.497 26.4096Z"
                fill="white"
              />
              <path
                d="M330.981 8.30157C335.274 8.30157 338.748 11.5664 338.748 15.9196C338.748 20.2727 335.274 23.6866 330.981 23.6866C326.687 23.6866 323.214 20.3621 323.214 15.9196C323.214 11.477 326.687 8.30157 330.981 8.30157ZM330.981 21.9051C334.134 21.9051 336.698 19.3335 336.698 15.9196C336.698 12.5056 334.127 10.1129 330.981 10.1129C327.835 10.1129 325.264 12.5951 325.264 15.9196C325.264 19.244 327.835 21.9051 330.981 21.9051Z"
                fill="white"
              />
              <path
                d="M343.034 10.4928H341.192V8.83051H343.034V8.12983C343.034 3.28473 346.447 2.67352 348.11 2.67352C348.661 2.67352 349.012 2.73314 349.012 2.73314V4.51466C349.012 4.51466 348.721 4.45502 348.311 4.45502C347.17 4.45502 345.016 4.86498 345.016 8.12983V8.83051H348.781V10.4928H345.016V23.336H343.034V10.4928Z"
                fill="white"
              />
              <path
                d="M97.0765 43.8668H95.1533V42.2046H97.1063V37.9707H99.0592V42.2046H102.734V43.8668H99.0592V51.1643C99.0592 54.608 101.251 55.0478 102.354 55.0478C102.764 55.0478 103.025 54.9882 103.025 54.9882V56.7697C103.025 56.7697 102.704 56.8293 102.175 56.8293C100.483 56.8293 97.069 56.2777 97.069 51.373V43.8743L97.0765 43.8668Z"
                fill="white"
              />
              <path
                d="M106.297 36.1893H108.28V44.1278C108.28 44.7987 108.22 45.3205 108.22 45.3205H108.28C108.831 43.9191 110.792 41.6755 114.116 41.6755C117.731 41.6755 119.021 43.7477 119.021 47.3629V56.7027H117.038V47.8921C117.038 45.5888 116.688 43.5464 113.855 43.5464C111.343 43.5464 109.189 45.2683 108.511 47.7207C108.339 48.3021 108.28 48.9729 108.28 49.6736V56.7102H106.297V36.1893Z"
                fill="white"
              />
              <path
                d="M129.822 41.6753C133.877 41.6753 135.949 44.7985 135.949 48.4436C135.949 48.7939 135.889 49.4647 135.889 49.4647H124.626C124.716 53.229 127.28 55.2714 130.321 55.2714C133.005 55.2714 134.786 53.4899 134.786 53.4899L135.688 55.0105C135.688 55.0105 133.556 57.0529 130.262 57.0529C125.886 57.0529 122.584 53.8999 122.584 49.3753C122.584 44.56 125.856 41.6678 129.822 41.6678V41.6753ZM133.936 47.8323C133.817 44.7687 131.954 43.3376 129.762 43.3376C127.31 43.3376 125.178 44.888 124.716 47.8323H133.936Z"
                fill="white"
              />
              <path
                d="M152.564 47.8921L145.646 36.1893H147.95L152.295 43.6284C152.907 44.6794 153.548 46.0808 153.548 46.0808H153.607C153.607 46.0808 154.219 44.7092 154.86 43.6284L159.205 36.1893H161.479L154.591 47.8921V56.7027H152.549V47.8921H152.564Z"
                fill="white"
              />
              <path
                d="M168.851 41.6753C172.906 41.6753 174.978 44.7985 174.978 48.4436C174.978 48.7939 174.918 49.4647 174.918 49.4647H163.655C163.745 53.229 166.309 55.2714 169.343 55.2714C172.026 55.2714 173.808 53.4899 173.808 53.4899L174.71 55.0105C174.71 55.0105 172.578 57.0529 169.283 57.0529C164.908 57.0529 161.605 53.8999 161.605 49.3753C161.605 44.56 164.878 41.6678 168.843 41.6678L168.851 41.6753ZM172.965 47.8323C172.846 44.7687 170.983 43.3376 168.791 43.3376C166.339 43.3376 164.207 44.888 163.745 47.8323H172.965Z"
                fill="white"
              />
              <path
                d="M186.569 47.6312H187.389V47.2511C187.389 44.4186 185.838 43.457 183.744 43.457C181.321 43.457 179.599 44.8583 179.599 44.8583L178.668 43.3377C178.668 43.3377 180.591 41.6755 183.893 41.6755C187.426 41.6755 189.379 43.6284 189.379 47.3107V56.7102H187.486V55.1076C187.486 54.3771 187.545 53.8851 187.545 53.8851H187.486C187.486 53.8851 186.263 57.068 182.581 57.068C180.129 57.068 177.587 55.6368 177.587 52.7223C177.587 47.7579 184.035 47.6461 186.576 47.6461L186.569 47.6312ZM182.894 55.3685C185.697 55.3685 187.389 52.4465 187.389 49.9121V49.2711H186.539C184.236 49.2711 179.622 49.3605 179.622 52.5956C179.622 53.9671 180.703 55.3685 182.894 55.3685Z"
                fill="white"
              />
              <path
                d="M193.948 42.0257H195.901V44.6197C195.901 45.2607 195.842 45.7899 195.842 45.7899H195.901C196.602 43.5686 198.234 41.8766 200.545 41.8766C200.955 41.8766 201.335 41.9661 201.335 41.9661V43.919C201.335 43.919 200.985 43.8594 200.605 43.8594C198.532 43.8594 196.989 45.4918 196.341 47.6534C196.05 48.5852 195.931 49.5766 195.931 50.5158V56.7026H193.948V42.0257Z"
                fill="white"
              />
              <path
                d="M222.728 51.3955H215.46L213.709 56.7102H209.825L217.123 35.9881H221.066L228.363 56.7102H224.48L222.728 51.3955ZM219.083 39.5735C219.083 39.5735 218.613 41.6159 218.211 42.7861L216.37 48.3319H221.796L219.955 42.7861C219.575 41.6159 219.135 39.5735 219.135 39.5735H219.076H219.083Z"
                fill="white"
              />
              <path
                d="M228.624 41.8244H232.538L235.132 51.604C235.333 52.3942 235.422 53.1768 235.422 53.1768H235.482C235.482 53.1768 235.631 52.3867 235.832 51.604L238.575 41.8542H241.87L244.613 51.604C244.814 52.3942 244.934 53.1768 244.934 53.1768H244.993C244.993 53.1768 245.113 52.3867 245.314 51.604L247.908 41.8244H251.761L247.237 56.71H243.122L240.61 48.2199C240.379 47.4298 240.23 46.6173 240.23 46.6173H240.171C240.171 46.6173 240.051 47.4373 239.82 48.2199L237.308 56.71H233.164L228.609 41.8244H228.624Z"
                fill="white"
              />
              <path
                d="M261.787 47.3702H262.287V47.1689C262.287 45.216 261.064 44.5451 259.424 44.5451C257.292 44.5451 255.339 45.9763 255.339 45.9763L253.938 43.3226C253.938 43.3226 256.241 41.4815 259.775 41.4815C263.688 41.4815 265.961 43.6432 265.961 47.4074V56.7175H262.547V55.495C262.547 54.9136 262.607 54.444 262.607 54.444H262.547C262.547 54.444 261.407 57.0678 258.023 57.0678C255.369 57.0678 252.977 55.4056 252.977 52.424C252.977 47.6982 259.365 47.3776 261.787 47.3776V47.3702ZM258.955 54.1682C260.908 54.1682 262.309 52.0662 262.309 50.2549V49.9045H261.668C259.775 49.9045 256.704 50.1654 256.704 52.2376C256.704 53.229 257.434 54.1608 258.947 54.1608L258.955 54.1682Z"
                fill="white"
              />
              <path
                d="M269.815 41.8243H273.378V44.3959C273.378 44.9475 273.319 45.447 273.319 45.447H273.378C274.049 43.3151 275.831 41.623 278.104 41.623C278.455 41.623 278.775 41.6827 278.775 41.6827V45.3277C278.775 45.3277 278.395 45.2681 277.903 45.2681C276.33 45.2681 274.549 46.17 273.848 48.3913C273.617 49.1218 273.528 49.9417 273.528 50.8138V56.71H269.823V41.8243H269.815Z"
                fill="white"
              />
              <path
                d="M287.004 41.4741C290.276 41.4741 291.298 43.3674 291.298 43.3674H291.357C291.357 43.3674 291.327 42.9872 291.327 42.5176V35.9805H295.032V56.7026H291.529V55.4801C291.529 54.9285 291.588 54.5186 291.588 54.5186H291.529C291.529 54.5186 290.276 57.0604 286.862 57.0604C282.837 57.0604 280.266 53.8775 280.266 49.2709C280.266 44.6644 283.068 41.4815 287.004 41.4815V41.4741ZM287.705 53.9371C289.628 53.9371 291.439 52.5358 291.439 49.2411C291.439 46.9378 290.217 44.6569 287.735 44.6569C285.692 44.6569 284 46.3192 284 49.2709C284 52.2227 285.521 53.9371 287.705 53.9371Z"
                fill="white"
              />
              <path
                d="M299.557 52.275C299.557 52.275 301.189 53.9074 303.612 53.9074C304.663 53.9074 305.565 53.4378 305.565 52.5657C305.565 50.3444 298.327 50.6425 298.327 45.8496C298.327 43.0767 300.809 41.4741 304.022 41.4741C307.234 41.4741 309.016 43.1662 309.016 43.1662L307.644 45.9092C307.644 45.9092 306.273 44.6271 303.999 44.6271C303.038 44.6271 302.106 45.0371 302.106 45.9689C302.106 48.0709 309.344 47.81 309.344 52.5955C309.344 55.0777 307.182 57.0604 303.649 57.0604C299.944 57.0604 297.872 54.869 297.872 54.869L299.564 52.275H299.557Z"
                fill="white"
              />
              <path
                d="M101.504 16.1006H107.706L104.665 8.22176L101.504 16.1006ZM114.258 20.4612C114.586 21.311 114.75 21.5793 115.145 21.8477C115.518 22.1384 115.808 22.3471 116.285 22.444L117.15 22.608V23.6814H108.101V22.608L108.556 22.526C108.928 22.444 109.323 22.28 109.547 21.8626C109.733 21.5123 109.525 20.8712 109.361 20.4165L108.265 17.42H101.072L99.73 20.7072C99.5213 21.2066 99.5436 21.7433 99.7896 22.0713C99.9163 22.2353 100.2 22.4813 100.654 22.5483L101.169 22.608V23.6814H95.1309V22.608L96.0626 22.362C96.3906 22.28 96.972 21.9893 97.3 21.6762C97.7323 21.2439 98.2466 20.4538 98.6641 19.44L105.261 3.42139H107.311L114.258 20.4538V20.4612Z"
                fill="white"
              />
              <path
                d="M126.931 12.0754L126.58 11.576C125.88 10.6442 124.985 10.3163 123.971 10.2343C121.139 10.0107 120.125 13.2755 120.021 15.9589C119.902 18.7467 120.93 21.7656 123.785 22.1159C125.462 22.347 126.931 20.7966 126.931 19.0971V12.068V12.0754ZM130.546 19.2909C130.546 20.737 130.687 21.624 131.396 21.8774C131.664 21.9818 131.791 21.9594 132.119 21.937L132.573 21.8774V22.7495L127.408 24.054C127.199 23.6813 127.095 23.4129 127.013 23.003C126.908 22.511 126.908 22.1979 126.975 21.7209L125.03 23.085C124.18 23.6589 123.539 24.0167 122.548 24.0987C118.768 24.4118 116.137 20.5655 116.1 16.7789C116.063 12.6046 118.91 9.29507 123.83 9.2578C124.739 9.23544 125.276 9.37707 126.141 9.58578L126.931 9.79449V6.20166C126.931 5.7246 126.931 5.37427 126.595 5.0612C126.304 4.77049 125.932 4.6885 125.477 4.66614L124.754 4.62885V3.86109H125.477C126.468 3.86109 127.05 3.77911 128.019 3.61512C128.66 3.51077 129.055 3.45113 129.674 3.22006L130.546 2.90698V19.2983V19.2909Z"
                fill="white"
              />
              <path
                d="M145.99 3.53333V15.8399H145.938L138.998 3.53333H133.653V23.5026H138.551V11.0618H138.61L145.542 23.5026H150.887V3.53333H145.99Z"
                fill="#00AEE8"
              />
              <path
                d="M163.507 14.7516C163.425 13.1267 162.277 11.7849 160.681 11.7849C159.004 11.7849 157.886 12.985 157.603 14.7516H163.507ZM157.603 17.4052C157.685 19.3955 158.833 20.7074 160.845 20.7074C161.993 20.7074 163.111 20.1781 163.559 19.1122H168.009C167.137 22.4665 164.036 23.9275 160.786 23.9275C156.06 23.9275 152.84 21.0726 152.84 16.2052C152.84 11.7551 156.395 8.56482 160.674 8.56482C165.877 8.56482 168.478 12.4782 168.255 17.4052H157.595H157.603Z"
                fill="#00AEE8"
              />
              <path
                d="M186.808 8.98932L184.43 18.359H184.378L182.246 8.98932H177.714L175.65 18.3888H175.59L173.153 8.98932H168.345L172.877 23.5097H177.685L179.958 13.8568H180.01L182.306 23.5097H187.114L191.646 8.98932H186.808Z"
                fill="#00AEE8"
              />
              <path
                d="M196.237 18.7245C196.237 19.455 196.543 19.9842 197.02 20.3495C197.467 20.6849 198.108 20.8563 198.779 20.8563C199.703 20.8563 201.015 20.4613 201.015 19.3133C201.015 18.1654 199.532 17.9716 198.69 17.7778C195.701 17.025 191.951 16.9355 191.951 13.0222C191.951 9.52624 195.73 8.57959 198.578 8.57959C201.768 8.57959 205.1 9.50388 205.264 13.2756H200.874C200.874 12.6569 200.65 12.2693 200.255 12.0159C199.86 11.7624 199.331 11.6506 198.742 11.6506C197.959 11.6506 196.7 11.7326 196.7 12.7389C196.7 14.1104 199.89 14.3639 202.074 14.8409C205.011 15.4298 205.764 17.5542 205.764 18.7543C205.764 22.6453 202.074 23.9274 198.794 23.9274C195.358 23.9274 191.825 22.7794 191.69 18.7245H196.237Z"
                fill="#00AEE8"
              />
              <g
                clipPath="url(#clip2_88_3667)"
                filter="url(#filter1_d_88_3667)"
              >
                <path
                  d="M323.934 44.1211L318.052 40.5024C319.603 36.366 322.868 33.7802 327.101 33.7802C331.851 33.7802 335.147 36.9153 335.147 41.1808C335.147 44.3151 332.853 47.1265 330.365 48.7103H335.438V56.7892H317.535L323.934 44.1211Z"
                  fill="#B79D62"
                />
                <path
                  d="M337.603 45.4458C337.603 38.7243 342.547 33.7798 349.333 33.7798C356.055 33.7798 360.999 38.7243 360.999 45.4458C360.999 52.2321 356.055 57.1767 349.366 57.1767C342.547 57.1767 337.603 52.2321 337.603 45.4458Z"
                  fill="#B79D62"
                />
                <path
                  d="M368.365 44.1209L362.484 40.5021C364.035 36.3657 367.299 33.7799 371.533 33.7799C376.283 33.7799 379.579 36.9151 379.579 41.1805C379.579 44.3149 377.285 47.1263 374.796 48.7101H379.87V56.789H361.967L368.365 44.1209Z"
                  fill="#B79D62"
                />
                <path
                  d="M382.292 50.6167L388.82 48.3221L384.199 44.606L388.012 41.5033L382.906 38.4338C384.522 35.6541 387.107 33.7798 390.759 33.7798C395.057 33.7798 398.417 36.8825 398.417 40.3722C398.417 41.9236 397.933 43.3459 396.963 44.6385C398.45 46.0276 399.452 47.9343 399.452 50.164C399.452 54.2363 395.994 57.1767 391.405 57.1767C386.655 57.1767 383.358 54.4943 382.292 50.6167Z"
                  fill="#B79D62"
                />
              </g>
            </g>
            <g filter="url(#filter2_d_88_3667)">
              <path
                d="M475.129 10.621L483.922 34.6011L490.602 10.621H500.537L487.747 50.5879H480.724L471.532 24.381L462.34 50.5879H455.317L442.527 10.621H452.462L459.142 34.6011L467.935 10.621H475.129ZM515.158 50.5879H505.452V10.621H515.158V50.5879ZM552.345 34.4298V10.621H562.051V50.5879H553.487L533.161 26.8361V50.5879H523.455V10.621H532.019L552.345 34.4298ZM598.958 34.4298V10.621H608.664V50.5879H600.1L579.774 26.8361V50.5879H570.068V10.621H578.632L598.958 34.4298ZM626.387 42.1377H644.829V50.5879H616.681V10.621H644.601V19.0711H626.387V26.0939H644.087V34.544H626.387V42.1377ZM674.713 50.5879L665.121 40.0252H661.124V50.5879H651.418V10.621H670.887C675.189 10.6971 678.71 12.1055 681.45 14.8461C684.153 17.5866 685.542 21.0885 685.618 25.3516C685.58 28.5109 684.781 31.2515 683.22 33.5734C681.659 35.9334 679.566 37.6843 676.94 38.8262L687.788 50.5879H674.713ZM661.124 31.6893H669.517C671.344 31.6512 672.867 31.0802 674.085 29.9764C675.265 28.8725 675.874 27.331 675.912 25.3516C675.874 23.3723 675.265 21.8117 674.085 20.6698C672.867 19.566 671.344 18.995 669.517 18.9569H661.124V31.6893Z"
                fill="#B79D62"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_88_3667"
                x="92.1337"
                y="0.260986"
                width="339.548"
                height="69.0446"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="3.78326" />
                <feGaussianBlur stdDeviation="1.89163" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_88_3667"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_88_3667"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_d_88_3667"
                x="313.226"
                y="28.5993"
                width="93.6055"
                height="38.4913"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="2.22509" />
                <feGaussianBlur stdDeviation="1.11255" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_88_3667"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_88_3667"
                  result="shape"
                />
              </filter>
              <filter
                id="filter2_d_88_3667"
                x="438.744"
                y="10.621"
                width="252.827"
                height="47.5334"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="3.78326" />
                <feGaussianBlur stdDeviation="1.89163" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_88_3667"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_88_3667"
                  result="shape"
                />
              </filter>
              <clipPath id="clip0_88_3667">
                <rect
                  width="59.4755"
                  height="59.4755"
                  fill="white"
                  transform="translate(0.967773 1.26221)"
                />
              </clipPath>
              <clipPath id="clip1_88_3667">
                <rect
                  width="331.981"
                  height="61.4781"
                  fill="white"
                  transform="translate(95.917 0.260986)"
                />
              </clipPath>
              <clipPath id="clip2_88_3667">
                <rect
                  width="89.1552"
                  height="34.0411"
                  fill="white"
                  transform="translate(315.451 28.5993)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="mobile">
          <svg
            className="img-1"
            width="71"
            height="72"
            viewBox="0 0 71 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_88_4133)">
              <path
                d="M24.0417 53.4671H18.182V68.8422H9.71526V53.4671H3.2832V45.1361H24.0438V53.4671H24.0417Z"
                fill="#B79D62"
              />
              <path
                d="M44.2861 14.6826C44.2861 7.63845 49.4669 2.4577 56.58 2.4577C63.693 2.4577 68.8048 7.63845 68.8048 14.6826C68.8048 21.7267 63.6241 26.9764 56.6134 26.9764C49.6027 26.9764 44.2861 21.7957 44.2861 14.6826Z"
                fill="#B79D62"
              />
              <path
                d="M61.0839 58.514V68.8442H52.6172V59.7006L45.2012 45.1381H67.892L61.0839 58.514Z"
                fill="#B79D62"
              />
              <path
                d="M35.2559 2.37592V69.8384"
                stroke="white"
                strokeWidth="1.75851"
                strokeMiterlimit="10"
              />
              <path
                d="M0.172852 36.1073H70.3407"
                stroke="white"
                strokeWidth="1.75851"
                strokeMiterlimit="10"
              />
              <path
                d="M8.78052 17.3916H15.3045L12.1083 9.10661L8.78052 17.3916ZM22.1982 21.9791C22.545 22.8711 22.7205 23.151 23.1341 23.4351C23.5248 23.7401 23.8298 23.9595 24.3311 24.066L25.2419 24.2394V25.3717H15.7181V24.2394L16.1965 24.1538C16.5872 24.066 17.0008 23.8926 17.2389 23.456C17.4332 23.0863 17.218 22.4136 17.0447 21.9331L15.8936 18.7808H8.32513L6.91087 22.2381C6.69361 22.7625 6.7145 23.3265 6.97772 23.6754C7.10933 23.8488 7.41223 24.1078 7.89062 24.1747L8.43376 24.2394V25.3717H2.08105V24.2394L3.0608 23.9804C3.40757 23.8926 4.01757 23.5876 4.36435 23.2618C4.82184 22.8043 5.3629 21.9791 5.7995 20.9137L12.7371 4.05957H14.8909L22.1982 21.9812V21.9791Z"
                fill="#00AEE8"
              />
            </g>
            <defs>
              <clipPath id="clip0_88_4133">
                <rect
                  width="70.3403"
                  height="70.3403"
                  fill="white"
                  transform="translate(0 0.727295)"
                />
              </clipPath>
            </defs>
          </svg>
          <svg
            className="img-2"
            width="175"
            height="73"
            viewBox="0 0 175 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_88_4023)" filter="url(#filter0_d_88_4023)">
              <path
                d="M69.9733 12.5841H66.1962L65.2859 15.3462H63.2676L67.0601 4.57678H69.1094L72.9019 15.3462H70.8836L69.9733 12.5841ZM68.0789 6.44012C68.0789 6.44012 67.8349 7.50156 67.6257 8.10976L66.6688 10.9919H69.489L68.5322 8.10976C68.3346 7.50156 68.1061 6.44012 68.1061 6.44012H68.0751H68.0789Z"
                fill="white"
              />
              <path
                d="M76.9077 16.8918C78.0893 16.8918 79.0926 16.392 79.0926 15.0091V14.5558C79.0926 14.3737 79.1236 14.1452 79.1236 14.1452H79.0926C78.6394 14.827 77.9692 15.1911 76.9387 15.1911C74.7229 15.1911 73.4639 13.4014 73.4639 11.2785C73.4639 9.15563 74.6609 7.42401 76.8922 7.42401C78.5774 7.42401 79.1817 8.40797 79.1817 8.40797H79.2282C79.2282 8.40797 79.2127 8.31887 79.2127 8.19491V7.60221H81.0179V14.8657C81.0179 17.5193 78.957 18.5227 76.9387 18.5227C75.9664 18.5227 74.9359 18.2476 74.1922 17.8564L74.7694 16.3843C74.7694 16.3843 75.7417 16.884 76.9077 16.884V16.8918ZM79.1391 11.294C79.1391 9.53527 78.2752 9.06265 77.2293 9.06265C76.0632 9.06265 75.4086 9.91103 75.4086 11.2165C75.4086 12.522 76.1059 13.568 77.3494 13.568C78.2752 13.568 79.1391 13.0373 79.1391 11.294Z"
                fill="white"
              />
              <path
                d="M86.5228 7.42798C88.7542 7.42798 89.9512 9.05114 89.9512 11.1004C89.9512 11.329 89.9047 11.8442 89.9047 11.8442H84.5665C84.6866 13.1962 85.7326 13.8935 86.887 13.8935C88.1305 13.8935 89.1183 13.0451 89.1183 13.0451L89.8621 14.4242C89.8621 14.4242 88.6806 15.5321 86.7514 15.5321C84.1869 15.5321 82.5947 13.6804 82.5947 11.4839C82.5947 9.10149 84.2179 7.43573 86.5228 7.43573V7.42798ZM88.0104 10.5232C87.9794 9.47727 87.3286 8.91555 86.4802 8.91555C85.4963 8.91555 84.8106 9.52375 84.6285 10.5232H88.0104Z"
                fill="white"
              />
              <path
                d="M91.543 7.61005H93.3947V8.50492C93.3947 8.79158 93.3637 9.03563 93.3637 9.03563H93.3947C93.7278 8.38482 94.5762 7.42798 96.1413 7.42798C97.8535 7.42798 98.8259 8.32285 98.8259 10.3721V15.3462H96.916V10.7673C96.916 9.82591 96.672 9.1906 95.688 9.1906C94.7041 9.1906 93.8983 9.8414 93.6116 10.7673C93.507 11.0849 93.476 11.4336 93.476 11.7977V15.3462H91.5507V7.61005H91.543Z"
                fill="white"
              />
              <path
                d="M104.485 7.42798C106.504 7.42798 107.457 8.5824 107.457 8.5824L106.608 9.90339C106.608 9.90339 105.802 9.07051 104.59 9.07051C103.164 9.07051 102.239 10.1474 102.239 11.4684C102.239 12.7894 103.18 13.8935 104.652 13.8935C105.957 13.8935 106.926 12.9095 106.926 12.9095L107.67 14.2731C107.67 14.2731 106.562 15.5321 104.485 15.5321C101.921 15.5321 100.282 13.7269 100.282 11.4839C100.282 9.24095 101.921 7.43573 104.485 7.43573V7.42798Z"
                fill="white"
              />
              <path
                d="M108.701 16.5897C108.701 16.5897 109.127 16.9073 109.564 16.9073C110.095 16.9073 110.61 16.5897 110.901 15.9079L111.188 15.2261L107.926 7.61005H110.095L111.718 12.0689C111.869 12.4795 112.005 13.1148 112.005 13.1148H112.036C112.036 13.1148 112.156 12.5066 112.296 12.0999L113.814 7.61005H115.921L112.462 16.5122C111.916 17.9223 110.839 18.5305 109.673 18.5305C108.747 18.5305 108.065 18.0308 108.065 18.0308L108.701 16.5897Z"
                fill="white"
              />
              <path
                d="M124.543 7.17896C126.775 7.17896 128.58 8.87571 128.58 11.1381C128.58 13.4004 126.775 15.1746 124.543 15.1746C122.312 15.1746 120.507 13.4469 120.507 11.1381C120.507 8.82923 122.312 7.17896 124.543 7.17896ZM124.543 14.2488C126.182 14.2488 127.515 12.9123 127.515 11.1381C127.515 9.36382 126.178 8.1203 124.543 8.1203C122.909 8.1203 121.572 9.41031 121.572 11.1381C121.572 12.8658 122.909 14.2488 124.543 14.2488Z"
                fill="white"
              />
              <path
                d="M130.807 8.31766H129.851V7.45378H130.807V7.08964C130.807 4.57162 132.582 4.25397 133.446 4.25397C133.732 4.25397 133.914 4.28495 133.914 4.28495V5.21082C133.914 5.21082 133.763 5.17982 133.55 5.17982C132.957 5.17982 131.838 5.39288 131.838 7.08964V7.45378H133.794V8.31766H131.838V14.9924H130.807V8.31766Z"
                fill="white"
              />
              <path
                d="M2.98188 25.6622H1.98242V24.7983H2.99738V22.598H4.01233V24.7983H5.92215V25.6622H4.01233V29.4547C4.01233 31.2445 5.15125 31.473 5.72459 31.473C5.93765 31.473 6.07324 31.442 6.07324 31.442V32.3679C6.07324 32.3679 5.90666 32.3989 5.63161 32.3989C4.75224 32.3989 2.97801 32.1122 2.97801 29.5632V25.6661L2.98188 25.6622Z"
                fill="white"
              />
              <path
                d="M7.77344 21.6722H8.80389V25.7979C8.80389 26.1465 8.7729 26.4177 8.7729 26.4177H8.80389C9.09056 25.6894 10.1094 24.5233 11.8371 24.5233C13.716 24.5233 14.3861 25.6003 14.3861 27.4791V32.3331H13.3557V27.7542C13.3557 26.5571 13.1736 25.4957 11.7015 25.4957C10.396 25.4957 9.2765 26.3906 8.92398 27.6651C8.83488 27.9672 8.80389 28.3159 8.80389 28.68V32.337H7.77344V21.6722Z"
                fill="white"
              />
              <path
                d="M19.9998 24.5234C22.1072 24.5234 23.1841 26.1466 23.1841 28.0409C23.1841 28.223 23.1531 28.5716 23.1531 28.5716H17.2997C17.3462 30.5279 18.6788 31.5894 20.2594 31.5894C21.654 31.5894 22.5798 30.6635 22.5798 30.6635L23.0486 31.4538C23.0486 31.4538 21.9406 32.5152 20.2284 32.5152C17.9544 32.5152 16.2383 30.8766 16.2383 28.5251C16.2383 26.0226 17.9389 24.5195 19.9998 24.5195V24.5234ZM22.1382 27.7232C22.0762 26.1311 21.1077 25.3873 19.9688 25.3873C18.6943 25.3873 17.5864 26.1931 17.3462 27.7232H22.1382Z"
                fill="white"
              />
              <path
                d="M31.8186 27.7542L28.2236 21.6722H29.4207L31.6791 25.5383C31.9968 26.0845 32.3299 26.8128 32.3299 26.8128H32.3609C32.3609 26.8128 32.6786 26.1 33.0117 25.5383L35.2702 21.6722H36.4517L32.8723 27.7542V32.3331H31.8108V27.7542H31.8186Z"
                fill="white"
              />
              <path
                d="M40.283 24.5234C42.3904 24.5234 43.4673 26.1466 43.4673 28.0409C43.4673 28.223 43.4363 28.5716 43.4363 28.5716H37.5829C37.6294 30.5279 38.962 31.5894 40.5387 31.5894C41.9333 31.5894 42.8591 30.6635 42.8591 30.6635L43.3279 31.4538C43.3279 31.4538 42.2199 32.5152 40.5077 32.5152C38.2337 32.5152 36.5176 30.8766 36.5176 28.5251C36.5176 26.0226 38.2182 24.5195 40.2791 24.5195L40.283 24.5234ZM42.4214 27.7232C42.3594 26.1311 41.3909 25.3873 40.252 25.3873C38.9775 25.3873 37.8696 26.1931 37.6294 27.7232H42.4214Z"
                fill="white"
              />
              <path
                d="M49.4913 27.6185H49.9174V27.421C49.9174 25.9489 49.1116 25.4492 48.0231 25.4492C46.7641 25.4492 45.8692 26.1775 45.8692 26.1775L45.385 25.3872C45.385 25.3872 46.3844 24.5233 48.1005 24.5233C49.9368 24.5233 50.9517 25.5383 50.9517 27.452V32.3369H49.9677V31.504C49.9677 31.1244 49.9987 30.8687 49.9987 30.8687H49.9677C49.9677 30.8687 49.3324 32.5229 47.4187 32.5229C46.1442 32.5229 44.8232 31.7791 44.8232 30.2644C44.8232 27.6844 48.1741 27.6263 49.4951 27.6263L49.4913 27.6185ZM47.5814 31.6396C49.038 31.6396 49.9174 30.1211 49.9174 28.8039V28.4708H49.4758C48.2787 28.4708 45.8808 28.5173 45.8808 30.1985C45.8808 30.9113 46.4425 31.6396 47.5814 31.6396Z"
                fill="white"
              />
              <path
                d="M53.3262 24.7053H54.3411V26.0534C54.3411 26.3866 54.3101 26.6616 54.3101 26.6616H54.3411C54.7053 25.5072 55.5537 24.6278 56.7546 24.6278C56.9676 24.6278 57.1652 24.6743 57.1652 24.6743V25.6893C57.1652 25.6893 56.9831 25.6583 56.7855 25.6583C55.7086 25.6583 54.9067 26.5066 54.5697 27.6301C54.4186 28.1143 54.3566 28.6295 54.3566 29.1176V32.333H53.3262V24.7053Z"
                fill="white"
              />
              <path
                d="M68.2838 29.5748H64.5068L63.5964 32.3368H61.5781L65.3706 21.5674H67.4199L71.2125 32.3368H69.1942L68.2838 29.5748ZM66.3895 23.4308C66.3895 23.4308 66.1454 24.4922 65.9362 25.1004L64.9794 27.9826H67.7996L66.8427 25.1004C66.6452 24.4922 66.4166 23.4308 66.4166 23.4308H66.3856H66.3895Z"
                fill="white"
              />
              <path
                d="M71.3486 24.6006H73.3824L74.7305 29.6832C74.8351 30.0938 74.8815 30.5006 74.8815 30.5006H74.9125C74.9125 30.5006 74.99 30.0899 75.0946 29.6832L76.5202 24.6161H78.2324L79.658 29.6832C79.7626 30.0938 79.8246 30.5006 79.8246 30.5006H79.8556C79.8556 30.5006 79.9176 30.0899 80.0222 29.6832L81.3703 24.6006H83.3731L81.0216 32.3368H78.8833L77.5778 27.9244C77.4577 27.5138 77.3802 27.0915 77.3802 27.0915H77.3492C77.3492 27.0915 77.2872 27.5177 77.1671 27.9244L75.8616 32.3368H73.7078L71.3408 24.6006H71.3486Z"
                fill="white"
              />
              <path
                d="M88.5828 27.483H88.8424V27.3784C88.8424 26.3634 88.2071 26.0148 87.3548 26.0148C86.2469 26.0148 85.2319 26.7586 85.2319 26.7586L84.5036 25.3795C84.5036 25.3795 85.7007 24.4226 87.5369 24.4226C89.5707 24.4226 90.7522 25.546 90.7522 27.5023V32.3408H88.978V31.7055C88.978 31.4033 89.0089 31.1593 89.0089 31.1593H88.978C88.978 31.1593 88.3853 32.5229 86.6265 32.5229C85.2474 32.5229 84.0039 31.659 84.0039 30.1095C84.0039 27.6534 87.3238 27.4868 88.5828 27.4868V27.483ZM87.1108 31.016C88.1257 31.016 88.854 29.9235 88.854 28.9822V28.8001H88.5208C87.5369 28.8001 85.9408 28.9357 85.9408 30.0126C85.9408 30.5278 86.3205 31.0121 87.1069 31.0121L87.1108 31.016Z"
                fill="white"
              />
              <path
                d="M92.7549 24.6008H94.6066V25.9372C94.6066 26.2239 94.5756 26.4835 94.5756 26.4835H94.6066C94.9552 25.3755 95.8811 24.4962 97.0626 24.4962C97.2447 24.4962 97.4113 24.5272 97.4113 24.5272V26.4215C97.4113 26.4215 97.2137 26.3905 96.958 26.3905C96.1407 26.3905 95.2148 26.8592 94.8507 28.0136C94.7306 28.3933 94.6841 28.8194 94.6841 29.2727V32.3369H92.7588V24.6008H92.7549Z"
                fill="white"
              />
              <path
                d="M101.688 24.4188C103.388 24.4188 103.919 25.4028 103.919 25.4028H103.95C103.95 25.4028 103.934 25.2052 103.934 24.9612V21.5638H105.86V32.3332H104.039V31.6978C104.039 31.4112 104.07 31.1981 104.07 31.1981H104.039C104.039 31.1981 103.388 32.5191 101.614 32.5191C99.522 32.5191 98.1855 30.865 98.1855 28.4709C98.1855 26.0768 99.6421 24.4227 101.688 24.4227V24.4188ZM102.052 30.8959C103.051 30.8959 103.992 30.1677 103.992 28.4554C103.992 27.2584 103.357 26.073 102.067 26.073C101.006 26.073 100.126 26.9368 100.126 28.4709C100.126 30.005 100.917 30.8959 102.052 30.8959Z"
                fill="white"
              />
              <path
                d="M108.211 30.0318C108.211 30.0318 109.06 30.8802 110.319 30.8802C110.865 30.8802 111.334 30.6362 111.334 30.1829C111.334 29.0285 107.572 29.1834 107.572 26.6925C107.572 25.2515 108.862 24.4186 110.532 24.4186C112.202 24.4186 113.127 25.2979 113.127 25.2979L112.415 26.7235C112.415 26.7235 111.702 26.0572 110.52 26.0572C110.021 26.0572 109.536 26.2703 109.536 26.7545C109.536 27.847 113.298 27.7114 113.298 30.1984C113.298 31.4884 112.174 32.5189 110.338 32.5189C108.413 32.5189 107.336 31.3799 107.336 31.3799L108.215 30.0318H108.211Z"
                fill="white"
              />
              <path
                d="M5.28287 11.2321H8.50593L6.92539 7.13742L5.28287 11.2321ZM11.9111 13.4983C12.0815 13.9399 12.1667 14.0794 12.3721 14.2189C12.5658 14.3699 12.7168 14.4784 12.9648 14.5288L13.4141 14.614V15.1718H8.71125V14.614L8.94755 14.5714C9.14125 14.5288 9.34656 14.4436 9.46278 14.2266C9.55963 14.0445 9.45116 13.7114 9.36593 13.4751L8.79647 11.9178H5.05818L4.36088 13.6262C4.25242 13.8857 4.26404 14.1646 4.39188 14.3351C4.45773 14.4203 4.60494 14.5481 4.84125 14.583L5.10854 14.614V15.1718H1.9707V14.614L2.45494 14.4862C2.62539 14.4435 2.92755 14.2925 3.098 14.1298C3.32269 13.9051 3.58998 13.4945 3.80692 12.9676L7.2353 4.64264H8.30062L11.9111 13.4944V13.4983Z"
                fill="white"
              />
              <path
                d="M18.4971 9.14006L18.315 8.8805C17.9509 8.39627 17.486 8.22582 16.9592 8.18321C15.4871 8.06699 14.9602 9.76375 14.906 11.1583C14.844 12.6072 15.3786 14.1761 16.8623 14.3582C17.7339 14.4783 18.4971 13.6725 18.4971 12.7892V9.13618V9.14006ZM20.3759 12.89C20.3759 13.6415 20.4495 14.1025 20.8175 14.2342C20.957 14.2884 21.0229 14.2768 21.1933 14.2652L21.4296 14.2342V14.6875L18.745 15.3654C18.6366 15.1717 18.5823 15.0322 18.5397 14.8192C18.4855 14.5635 18.4855 14.4008 18.5203 14.1529L17.5093 14.8618C17.0676 15.1601 16.7345 15.346 16.2193 15.3886C14.2552 15.5513 12.8877 13.5524 12.8684 11.5845C12.849 9.4151 14.3288 7.6951 16.8856 7.67573C17.3582 7.66411 17.6371 7.73772 18.0865 7.84618L18.4971 7.95465V6.08744C18.4971 5.83951 18.4971 5.65744 18.3228 5.49474C18.1717 5.34366 17.978 5.30105 17.7417 5.28942L17.3659 5.27005V4.87104H17.7417C18.2569 4.87104 18.5591 4.82843 19.0627 4.74321C19.3958 4.68897 19.6011 4.65798 19.9227 4.53789L20.3759 4.37518V12.8938V12.89Z"
                fill="white"
              />
              <path
                d="M28.4025 4.70068V11.0964H28.3754L24.7688 4.70068H21.9912V15.0788H24.5363V8.61329H24.5673L28.17 15.0788H30.9476V4.70068H28.4025Z"
                fill="#00AEE8"
              />
              <path
                d="M37.5054 10.5309C37.4628 9.68642 36.8662 8.98912 36.0372 8.98912C35.1656 8.98912 34.5845 9.61282 34.4373 10.5309H37.5054ZM34.4373 11.91C34.4799 12.9443 35.0765 13.6261 36.1225 13.6261C36.719 13.6261 37.3001 13.3511 37.5325 12.7971H39.8453C39.392 14.5404 37.7805 15.2997 36.0915 15.2997C33.6354 15.2997 31.9619 13.816 31.9619 11.2863C31.9619 8.97363 33.8098 7.31561 36.0334 7.31561C38.7373 7.31561 40.0893 9.34939 39.9731 11.91H34.4334H34.4373Z"
                fill="#00AEE8"
              />
              <path
                d="M49.6151 7.53638L48.3794 12.4058H48.3522L47.2443 7.53638H44.889L43.8159 12.4213H43.7849L42.5182 7.53638H40.0195L42.3748 15.0827H44.8735L46.055 10.066H46.0822L47.2753 15.0827H49.774L52.1293 7.53638H49.6151Z"
                fill="#00AEE8"
              />
              <path
                d="M54.5164 12.5957C54.5164 12.9753 54.6752 13.2504 54.9231 13.4402C55.1556 13.6145 55.4887 13.7036 55.8374 13.7036C56.3177 13.7036 56.9995 13.4983 56.9995 12.9017C56.9995 12.3052 56.2286 12.2044 55.7909 12.1037C54.2375 11.7125 52.2889 11.666 52.2889 9.63219C52.2889 7.81534 54.253 7.32336 55.7328 7.32336C57.3908 7.32336 59.1224 7.80372 59.2076 9.7639H56.9259C56.9259 9.44237 56.8097 9.24093 56.6044 9.10922C56.3991 8.9775 56.124 8.9194 55.818 8.9194C55.4112 8.9194 54.7566 8.96201 54.7566 9.48498C54.7566 10.1978 56.4146 10.3295 57.5496 10.5774C59.0759 10.8835 59.4672 11.9875 59.4672 12.6112C59.4672 14.6334 57.5496 15.2997 55.8451 15.2997C54.0593 15.2997 52.2231 14.7031 52.1533 12.5957H54.5164Z"
                fill="#00AEE8"
              />
              <g
                clipPath="url(#clip1_88_4023)"
                filter="url(#filter1_d_88_4023)"
              >
                <path
                  d="M120.88 25.7944L117.824 23.9137C118.63 21.764 120.326 20.4202 122.526 20.4202C124.995 20.4202 126.708 22.0495 126.708 24.2663C126.708 25.8953 125.515 27.3563 124.222 28.1794H126.859V32.3781H117.555L120.88 25.7944Z"
                  fill="#B79D62"
                />
                <path
                  d="M127.983 26.4826C127.983 22.9894 130.553 20.4197 134.08 20.4197C137.573 20.4197 140.143 22.9894 140.143 26.4826C140.143 30.0094 137.573 32.5792 134.097 32.5792C130.553 32.5792 127.983 30.0094 127.983 26.4826Z"
                  fill="#B79D62"
                />
                <path
                  d="M143.971 25.794L140.915 23.9134C141.72 21.7637 143.417 20.4198 145.617 20.4198C148.086 20.4198 149.799 22.0492 149.799 24.2659C149.799 25.8949 148.606 27.356 147.313 28.1791H149.95V32.3777H140.646L143.971 25.794Z"
                  fill="#B79D62"
                />
                <path
                  d="M151.21 29.1699L154.602 27.9774L152.201 26.0461L154.183 24.4336L151.529 22.8384C152.369 21.3937 153.712 20.4197 155.61 20.4197C157.844 20.4197 159.59 22.0322 159.59 23.8458C159.59 24.6521 159.339 25.3912 158.835 26.063C159.607 26.7849 160.128 27.7758 160.128 28.9346C160.128 31.051 158.331 32.5792 155.946 32.5792C153.477 32.5792 151.764 31.1851 151.21 29.1699Z"
                  fill="#B79D62"
                />
              </g>
            </g>
            <g filter="url(#filter2_d_88_4023)">
              <path
                d="M19.2696 41.3546L23.8392 53.8172L27.3109 41.3546H32.474L25.8273 62.1256H22.1775L17.4002 48.5058L12.6229 62.1256H8.97311L2.3264 41.3546H7.48947L10.9612 53.8172L15.5308 41.3546H19.2696ZM40.0728 62.1256H35.0284V41.3546H40.0728V62.1256ZM59.3991 53.7282V41.3546H64.4434V62.1256H59.9925L49.429 49.7817V62.1256H44.3846V41.3546H48.8355L59.3991 53.7282ZM83.6241 53.7282V41.3546H88.6685V62.1256H84.2176L73.6541 49.7817V62.1256H68.6097V41.3546H73.0606L83.6241 53.7282ZM97.8792 57.734H107.463V62.1256H92.8348V41.3546H107.345V45.7462H97.8792V49.396H107.078V53.7875H97.8792V57.734ZM122.994 62.1256L118.009 56.6361H115.932V62.1256H110.888V41.3546H121.006C123.241 41.3942 125.071 42.1261 126.496 43.5504C127.9 44.9747 128.622 46.7947 128.662 49.0102C128.642 50.6521 128.226 52.0764 127.415 53.2831C126.604 54.5096 125.516 55.4196 124.151 56.013L129.789 62.1256H122.994ZM115.932 52.3039H120.294C121.243 52.2841 122.035 51.9874 122.668 51.4137C123.281 50.84 123.598 50.0389 123.617 49.0102C123.598 47.9816 123.281 47.1705 122.668 46.577C122.035 46.0034 121.243 45.7066 120.294 45.6869H115.932V52.3039Z"
                fill="#B79D62"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_88_4023"
                x="0.413703"
                y="3"
                width="176.465"
                height="35.8828"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.96618" />
                <feGaussianBlur stdDeviation="0.98309" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_88_4023"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_88_4023"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_d_88_4023"
                x="115.315"
                y="17.7277"
                width="48.6468"
                height="20.0041"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.15639" />
                <feGaussianBlur stdDeviation="0.578195" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_88_4023"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_88_4023"
                  result="shape"
                />
              </filter>
              <filter
                id="filter2_d_88_4023"
                x="0.359992"
                y="41.3546"
                width="131.395"
                height="24.7034"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.96618" />
                <feGaussianBlur stdDeviation="0.98309" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_88_4023"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_88_4023"
                  result="shape"
                />
              </filter>
              <clipPath id="clip0_88_4023">
                <rect
                  width="172.532"
                  height="31.9504"
                  fill="white"
                  transform="translate(2.37988 3)"
                />
              </clipPath>
              <clipPath id="clip1_88_4023">
                <rect
                  width="46.3344"
                  height="17.6913"
                  fill="white"
                  transform="translate(116.472 17.7277)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <button className="close-btn" onClick={this.closeAnnouncementBar}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 13L13 23M13 13L23 23"
              stroke="#F9FAFB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    )
  }
}

export default AnnouncementBar
